import axios from 'axios';
import moment from 'moment';
const axiosInstance = axios.create({
    baseURL: 'https://trungtamvanphongpham.vn/api/v1',
    // baseURL: 'http://localhost:3001/api/v1',
    headers: {
        Accept: 'application/json'
    }
})

axiosInstance.interceptors.response.use(
    response => response.data,
    error => {
        return Promise.reject(error.response)
    }
)
export const renderTokenSource = () => {
    return axios.CancelToken.source()
}
export const GET = (url, config, isToken = false) => {
    config = config || {}
    if (isToken) {
        config.headers = {
            ...(config.headers || {}),
            authentication: localStorage.getItem('token')
        }
    }
    config.params = {
        ...(config.params || {}),
        current: moment().valueOf(),
    }
    return axiosInstance.get(url, config)
}


export const POST = (url, payload, config, isToken = false) => {
    if (isToken) {
        config.headers = {
            ...(config.headers || {}),
            authentication: localStorage.getItem('token')
        }
    }
    return axiosInstance.post(url, payload, config)
}


export const PUT = (url, payload, config, isToken = false) => {
    if (isToken) {
        config.headers = {
            ...(config.headers || {}),
            authentication: localStorage.getItem('token')
        }
    }
    return axiosInstance.put(url, payload, config)
}


export const DELETE = (url, config, isToken = false) => {
    if (isToken) {
        config.headers = {
            ...(config.headers || {}),
            authentication: localStorage.getItem('token')
        }
    }
    return axiosInstance.delete(url, config)
}
